import _export from "../internals/export";
import _functionCall from "../internals/function-call";
var $ = _export;
var call = _functionCall;

// `URL.prototype.toJSON` method
// https://url.spec.whatwg.org/#dom-url-tojson
$({
  target: "URL",
  proto: true,
  enumerable: true
}, {
  toJSON: function toJSON() {
    return call(URL.prototype.toString, this);
  }
});
export default {};